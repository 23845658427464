@import 'src/scss/abstracts/variables';

.TextInput {
  &__container {
    label {
      display: inline-block;
      margin-bottom: 9px;
    }
    width: 100%;
    font-size: 14px;
    .ant-input {
      border: 1px solid #d9d9d9;
      border-radius: 4px;
    }
    input.ant-input {
      height: 30px;
    }
  }

  &__error {
    font-size: 11px;
    color: #c44;
    padding-top: 3px;
  }
}
