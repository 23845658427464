#modal_user-content {
    position: absolute;
    display:flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: white;
    border: 2px solid #000;
    padding: 12px;
}

#button_user-content {
    color:black;
    border:2px solid black;
}
