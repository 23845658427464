.MapInputs {
  &__Iterator {
    margin-top:14px;
    & > .RaSimpleFormIterator-line {
      & > section {
        flex-direction: row;

        & > div {
          margin: 5px;
        }
      }
    }
  }

}
