.Circle {
  transform: translate(-50%, -50%);
  border-radius: 50%;
  opacity: 0.8;
  border: 2px solid black;
  stroke-width: 2px;
  display:flex;
  justify-content:center;
  align-items: center;
  color:white;
}
